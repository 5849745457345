<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "公告",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "公告",
          active: true,
        }
      ],
      options: [
        { text: '全部', value: 0 },
        { text: '新聞稿', value: 1 },
        { text: '營運公告', value: 2 },
        { text: '節目公告', value: 3 }
      ],
      announceList: [],
      search: {
        page: 1,
        type: 0,
        word: ''
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: 'code',
          label: "項次",
          class: 'width-auto center'
        },
        {
          key: 'title',
          label: "公告標題",
          class: 'width-auto center'
        },
        {
          key: 'type',
          label: "公告類型",
          class: 'width-auto center'
        },
        {
          key: 'date',
          label: "公告時間",
          class: 'width-auto center'
        },
        {
          key: 'edit',
          label: "操作",
          class: 'width-auto center',
          stickyColumn: true
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted () {
    // Set the initial number of items
    // this.totalRows = this.items.length;
    // this.getAnnounceList()
  },
  watch: {
    'search.type' (_type) {
      this.reloadAnnounceList()
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeStatus (_status) {
      this.search_data.status = _status
    },
    reloadAnnounceList () {
      this.$refs.announceListTable.refresh()
    },

    // 獲得公告清單
    getAnnounceList (_table, _callback) {
      let vm = this
      this.search.page = vm.currentPage
      this.$root.apis.getAnnounceList(this.search, function (_response) {
        console.log(_response.body);
        let response = _response.body.data
        vm.totalRows = response.total
        vm.currentPage = response.page
        vm.perPage = response.per_page

        // vm.announceList = response.contents
        if (typeof _callback === 'function')
          _callback(response.contents)
      })
    },
    // 獲取公告失敗
    getAnnounceListError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },



    // 刪除單一公告
    deleteAnnounceDetail (_id) {
      var vm = this
      this.$root.common.confirmAction("刪除公告", "是否確定要刪除？", "刪除", "再想一下", 
        function (_result) {
          vm.$root.apis.deleteAnnounceDetail(_id, vm.deleteAnnounceDetailSuccess)
        }
      )
    },
    deleteAnnounceDetailSuccess (_response) {
      this.reloadAnnounceList()
    }

  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title" :items="items")
  .row
    .col-lg-12
      .card
        .card-body
          .header-row.mb-3
            .header-box
              .form-group.mb-0
                input.form-control(type="text", v-model="search.word" placeholder="Search..." @keyup.enter="reloadAnnounceList()")
                .radio-group.col-lg-8.ml-3
                  b-form-radio-group#radio-group-1(v-model="search.type", :options="options" name="radio-options")
              b-button.ml-lg-1.width-lg(variant="primary" to="/announcement/editor/create") 新增


          //- Table
          .table-responsive.mb-0
            b-table(
              ref="announceListTable"
              :items="getAnnounceList"
              :fields="fields"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            )
              //- 項次
              template(v-slot:cell(code)="row")
                .text-center
                  span {{ row.index + 1 }}

              //- 項次
              template(v-slot:cell(title)="row")
                router-link.table-link(:to="'/announcement/editor/' + row.item.id")
                  span {{ row.item.title }}

              //- 公告類型
              template(v-slot:cell(type)="row")
                .text-center
                  span(v-if="row.item.type === 1") 新聞稿
                  span(v-if="row.item.type === 2") 營運公告
                  span(v-if="row.item.type === 3") 節目公告

              //- 公告時間
              template(v-slot:cell(date)="row")
                .text-center
                  span(v-if="row.item.status === 0") 排程中
                  span(v-if="row.item.status === 1") 公告中
                .text-center
                  span {{ row.item.start_date }} {{ row.item.start_time  }} ~ {{ row.item.end_date }} {{ row.item.end_time  }}

              //- 編輯
              template(v-slot:cell(edit)="row")
                b-button.table-btn(variant="info" :to="'/announcement/editor/' + row.item.id") 編輯
                b-button.ml-3(variant="danger" size="sm" @click="deleteAnnounceDetail(row.item.id)")
                  i.fe-trash-2 



          .row.mb-md-2
            //- .col-6
            //-   div(id="tickets-table_length", class="dataTables_length")
            //-     label.d-inline-flex.align-items-center Show
            //-       b-form-select.mr-1.ml-1(v-model="perPage", size="sm", :options="pageOptions") 
            //-       | entries
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.row
  .header-row
    .header-title
      font-size: 24px
      font-weight: 700
    .header-box
      display: flex
      justify-content: space-between
      .form-group
        display: flex
        align-items: center
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep.table-link
  text-decoration: underline
  color: #0073b8
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &:active
    background: none
    border: none
  &:hover
    background: none
    border: none
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
@media (max-width: 992px)
  .header-box
    flex-direction: column
    gap: 1rem
    .form-group
      flex-direction: column
      gap: 1rem
</style>
